/**
 * Convention de nommage :
 * Les icônes finissent par "Icon"
 *
 * Les composants ont le même nom que leurs fichiers
 * Les pages listes finissent par "List"
 * Les pages détails finissent par "Details"
 *
 * Les routes finissent par "Route" quand children est à null
 * ou undefined, "Routes" quand children est un tableau
 *
 * Quand cela est possible, définir la route d'une liste au pluriel,
 * et les details au singulier
 */

import React from "react";
import Async from "../layouts/components/Async";

// #region Import des icônes
import { Description as DescriptionIcon, History } from "@material-ui/icons";
import { User as UserIcon, Users as UsersIcon } from "react-feather";
// #endregion Icônes

// #region Imports et définitions des routes du projet
// #region Composants de bases
// -- Imports --
const SignIn = Async(() => import("../pages/auth/SignIn"));
const ResetPassword = Async(() => import("../pages/auth/ResetPassword"));
const Page404 = Async(() => import("../pages/auth/Page404"));
const Page500 = Async(() => import("../pages/auth/Page500"));
const PageNetworkError = Async(() => import("../pages/auth/PageNetworkError"));
// const Dashboard = Async(() => import("../pages/pages/Dashboard"));

// -- Définitions --
const authRoutes = {
	id: "auth",
	path: "/auth",
	icon: <UsersIcon />,
	children: [
		{
			path: "/auth/login",
			name: "connexion",
			component: SignIn,
		},
		{
			path: "/auth/reset-password",
			name: "resetPassword",
			component: ResetPassword,
		},
		{
			path: "/404",
			name: "pageNonTrouvée",
			component: Page404,
		},
		{
			path: "/500",
			name: "erreurInterne",
			component: Page500,
		},
		{
			path: "/networkerror",
			name: "erreurRéseau",
			component: PageNetworkError,
		},
	],
};
// const dashboardRoute = {
// 	id: "dashboard",
// 	path: "/",
// 	icon: <DashboardIcon />,
// 	component: Dashboard,
// 	containsHome: true,
// };
// #endregion Composants de bases

// #region Adhérents
// -- Imports --
const CustomersList = Async(() => import("../pages/pages/CustomersList"));
const CustomerDetails = Async(() => import("../pages/pages/CustomerDetails"));
// -- Définitions --
const customersListRoute = {
	id: "customers",
	path: "/",
	icon: <UserIcon />,
	component: CustomersList,
};
const customerDetailsRoute = {
	id: "customer",
	path: "/customer/:no",
	component: CustomerDetails,
};
// #endregion Adhérents

// #region Prestations
// -- Imports --
const PurchaseHeadersList = Async(() => import("../pages/pages/PurchaseHeaderList"));
const PurchaseHeaderDetails = Async(() => import("../pages/pages/PurchaseHeaderDetails"));
// -- Définitions --
const purchaseHeaderListRoute = {
	id: "purchaseHeaders",
	path: "/purchase-headers",
	icon: <DescriptionIcon />,
	component: PurchaseHeadersList,
};
const purchaseHeaderDetailsRoute = {
	id: "purchaseHeader",
	path: "/purchase-header/:no",
	component: PurchaseHeaderDetails,
};
// #endregion Prestations
// #endregion Imports et définitions des routes du projet

// #region Prestations enregistrées
// -- Imports --
const PurchaseInvHeaderList = Async(() => import("../pages/pages/PurchaseInvHeaderList"));
const PurchaseInvHeaderDetails = Async(() => import("../pages/pages/PurchaseInvHeaderDetails"));
// -- Définitions --
const purchaseInvHeaderListRoute = {
	id: "purchaseInvHeaders",
	path: "/purchase-inv-headers",
	icon: <History />,
	component: PurchaseInvHeaderList,
};
const purchaseInvHeaderDetailsRoute = {
	id: "purchaseInvHeader",
	path: "/purchase-inv-header/:no",
	component: PurchaseInvHeaderDetails,
};
// #endregion Prestations enregistrées

// #region Export des routes
// Routes de bases nécessaires au projet
export const auth = [authRoutes];

// Toutes les autres pages utilisées au cours du projet
// L'ordre n'as pas d'importance ici, mais il est toujours bon d'être organisé
export const dashboard = [
	// dashboardRoute,
	// ---- Adhérents ----
	customersListRoute,
	customerDetailsRoute,
	// ---- Adhérents ----
	purchaseHeaderListRoute,
	purchaseHeaderDetailsRoute,
	purchaseInvHeaderListRoute,
	purchaseInvHeaderDetailsRoute,
];

// Définition du menu (sidebar)
// L'ordre ici défini la structure du menu, c'est donc très important !
export default [
	// dashboardRoute, // TDB
	customersListRoute,
	purchaseHeaderListRoute,
	purchaseInvHeaderListRoute,
];
// #endregion Export des routes
