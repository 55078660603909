import { AppBar, Button, Grid, Menu, MenuItem, Toolbar } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { AccountCircle, ChevronLeft, Menu as MenuIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, withRouter } from "react-router-dom";
import styled from "styled-components";
import { showTranslations } from "translation-check";
import { parseJwt, removeToken } from "../../api";
import { getDevMode } from "../../api/localStorage";
import { IconButton } from "../../pages/components";

// #region Customisation de certains éléments
const Flag = styled.img`
	border-radius: 50%;
	width: 22px;
	height: 22px;
`;

const AccountIcon = styled(AccountCircle)`
	height: 28px;
	width: 28px;
`;

const useStyles = makeStyles((theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		width: `calc(100% - ${theme.sidebar.width}px)`,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
	drawer: {
		width: theme.sidebar.width,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},
	toolbar: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	logo: {
		maxWidth: "250px",
		maxHeight: "55px",
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
}));
// #endregion Customisation de certains éléments

const LanguageMenu = React.forwardRef((props, ref) => {
	const { t, i18n } = useTranslation();

	const [anchorMenu, setAnchorMenu] = useState(null);

	const toggleMenu = (event) => {
		if (event.ctrlKey && getDevMode()) return showTranslations(i18n);

		setAnchorMenu(event.currentTarget);
	};

	const setLanguage = (lng) => {
		// On évite d'appeler la fonction si ce n'est pas nécessaire
		if (i18n.language !== lng) {
			i18n.changeLanguage(lng);
		}
		// Quoi qu'il arrive, on ferme le menu
		closeMenu();
	};

	const closeMenu = () => {
		setAnchorMenu(null);
	};

	return (
		<MenuItem ref={ref}>
			<Button
				color="inherit"
				variant="text"
				onClick={toggleMenu}
				endIcon={<Flag src={t("translation:lngFlag")} alt={t("translation:lngName")} />}
				style={{ padding: 0, fontSize: "16px", fontWeight: 400 }} // Copie du style des autre boutons du menu
			>
				{t("translation:chooseLanguage")}
			</Button>
			<Menu id="menu-appbar" anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={closeMenu}>
				<MenuItem onClick={() => setLanguage("en")}>{t("translation:english")}</MenuItem>
				<MenuItem onClick={() => setLanguage("fr")}>{t("translation:french")}</MenuItem>
			</Menu>
		</MenuItem>
	);
});

const UserMenu = withRouter(() => {
	const { t } = useTranslation();
	const history = useHistory();

	// #region Gestion ouverture / fermeture du menu
	const [anchorMenu, setAnchorMenu] = useState(null);

	const toggleMenu = (event) => {
		setAnchorMenu(event.currentTarget);
	};

	const closeMenu = () => {
		setAnchorMenu(null);
	};
	// #endregion Gestion ouverture / fermeture du menu

	const logout = () => {
		removeToken();
		history.push("/auth/login");
	};

	// Récupération du nom de l'utilisateur (si disponible)
	const { AccFirstName, AccLogin } = parseJwt();
	var username = AccFirstName || AccLogin || "";

	return (
		<>
			<Button color="inherit" variant="text" onClick={toggleMenu} startIcon={<AccountIcon />}>
				{username}
			</Button>
			<Menu open={Boolean(anchorMenu)} anchorEl={anchorMenu} onClose={closeMenu}>
				<LanguageMenu />
				<MenuItem onClick={logout} style={{ backgroundColor: red[700], color: "#EEE" }}>
					{t("translation:disconnect")}
				</MenuItem>
			</Menu>
		</>
	);
});

export default function Header({ open, setOpen }) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<AppBar
			position="fixed"
			className={clsx(classes.appBar, {
				[classes.appBarShift]: open,
			})}
		>
			<Toolbar>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={() => setOpen(!open)}
					edge="start"
					icon={open ? <ChevronLeft /> : <MenuIcon />}
					title={t(`translation:${open ? "close" : "open"}Drawer`)}
				/>

				<Grid container alignItems="center">
					<Grid item xs container alignItems="center"></Grid>
					{/* Menu de profil */}
					<Grid item>
						<UserMenu />
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	);
}
